var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import styles from "./LoadablehHeaderAvatar.module.css";
import cn from "classnames";
import useOnScreen from "../../../hooks/lazy-loading/useOnScreen/useOnScreen";
var LoadablehHeaderAvatar = function (props) {
    var _a, _b;
    var src = props.src, _c = props.alt, alt = _c === void 0 ? "" : _c, isLoading = props.isLoading;
    var _d = useState(!isLoading), isLoaded = _d[0], setIsLoaded = _d[1];
    var imageRef = useRef(null);
    var containerRef = useRef(null);
    var isVisible = useOnScreen(containerRef);
    useEffect(function () {
        if (!isVisible || isLoaded) {
            return;
        }
        if (imageRef.current) {
            imageRef.current.onload = function () {
                setIsLoaded(true);
            };
        }
    }, [isVisible, isLoaded, isLoading]);
    return (_jsx("div", __assign({ ref: containerRef, className: cn(styles.container, (_a = {},
            _a[styles.containerLoaded] = isVisible,
            _a)) }, { children: isLoaded ? (_jsx("img", { ref: imageRef, className: cn(styles.image, (_b = {},
                _b[styles.imageLoaded] = isVisible,
                _b)), src: src, alt: alt })) : null })));
};
export default LoadablehHeaderAvatar;
